import React, { useMemo } from 'react'
import { useAddonContext } from '../context/AddonFeatureContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import useWindowSize from '../hooks/useWindowSize'
import { useSelector } from 'react-redux'
import { selectSelectedItems } from '../redux/searchSlice'
import { selectLandingPageDetails } from '../redux/otherDetailsSlice'

const Stepper = ({ handleTotalPrice, handleDiscountedPrice, price }) => {

    const selectedSkills = useSelector(selectSelectedItems)
    const landingPage = useSelector(selectLandingPageDetails)

    const skillName = landingPage?.skill_name

    const { step, selectedAtleastOne, secondAddOnSelected, threeCertificates, fiveCertificates, sevenCertificates, personalisedSelected, proMembershipSelected, personaliseAndCertificate, fourWeekPlacementFeature } = useAddonContext()
    const { height, width } = useWindowSize()

    const multipleSkillsSelected = useMemo(() => {
        return selectedAtleastOne || selectedSkills?.length > 0
    }, [selectedAtleastOne, selectedSkills])

    return (
        <div className={`${height <= 780 ? 'p-2 md:p-3' : 'p-3'} bg-[#2D4F71] rounded-xl`}>
            <div className={`w-full h-2 md:h-3 bg-[#133454] text-xl rounded relative text-white font-bold ${height <= 780 ? 'mt-2.5' : 'mt-3'}`}>
                <div className={`w-[10%] h-2 md:h-3 absolute top-0 ${multipleSkillsSelected ? 'bg-[#7FC241]' : 'bg-[#00C1FF]'} z-10 rounded-l`}>
                    <div className={`${height <= 780 ? 'w-6 md:w-8 h-6 md:h-8 md:-top-3 -top-2 text-sm' : 'w-8 h-8 -top-3 text-base'} absolute left-[95%] ${multipleSkillsSelected ? 'bg-[#7FC241]' : 'bg-[#00C1FF] text-[#2F587C] '} md:text-lg rounded-full flex justify-center items-center`}>
                        {multipleSkillsSelected ? <FontAwesomeIcon icon={faCheck} className='md:text-xl'/> : 1}
                    </div>
                </div>
                <div className={`w-2/5 ${width < 540 ? 'left-[15%]' : 'left-[13%]'} h-2 md:h-3 absolute top-0 ${secondAddOnSelected ? 'bg-[#7FC241]' : step === 2 ? 'bg-[#00C1FF]' :''} rounded-l`}>
                    <div className={`${height <= 780 ? 'w-6 md:w-8 h-6 md:h-8 md:-top-3 -top-2 text-sm' : 'w-8 h-8 -top-3 text-base'} ${width < 540 ? 'left-[85%]' : 'left-[90%]'} absolute ${secondAddOnSelected ? 'bg-[#7FC241]' : step === 2 ? 'bg-[#00C1FF] text-[#2F587C]' : 'bg-[#1D374F]'} md:text-lg border border-[#2D4F71] rounded-full flex justify-center items-center`}>
                        {secondAddOnSelected ? <FontAwesomeIcon icon={faCheck} className='md:text-xl'/> : 2}
                    </div>
                </div>
                <div className={`${height <= 780 ? 'w-7 h-7 md:w-8 md:h-8 -top-2.5' : 'w-8 h-8 -top-3'} right-0 absolute bg-[#1D374F] border border-[#2D4F71] rounded-full flex justify-center items-center`}>
                    <img src="https://ik.imagekit.io/238r9emirjj/LinkdinUsers/Certificate.svg?updatedAt=1700641480527" alt="" width={height <= 780 ? (width < 560 ? 16 : 18) : 18} height={height <= 780 ? 16 : 18} />
                </div>
            </div>
            <div className={`w-full flex items-center gap-2 text-white ${height <= 780 ? 'mt-4' : 'mt-6'}`}>
                <div className={`${height <= 780 ? 'w-[50px] md:w-[62px]' : 'w-[62px]'} flex-shrink-0`}>
                    <img src="https://ik.imagekit.io/238r9emirjj/LinkdinUsers/Hiten%202.svg?updatedAt=1700641980816" alt="" width={62} height={44} className='w-full h-auto'/>
                </div>
                <div className='flex-1'>
                    <p className='text-sm text-[#B0C1CD] md:text-xl'>In Process Of Unlocking Your {threeCertificates ? <span><span className='text-span_color font-bold'>4</span> Certificates</span> : fiveCertificates ? <span><span className='text-span_color font-bold'>6</span> Certificates</span> : sevenCertificates ? <span><span className='text-span_color font-bold'>8</span> Certificates</span> : selectedSkills?.length > 0 ? <span><span className='text-span_color font-bold'>{selectedSkills?.length + 1} Certificates</span></span> : `${skillName} Certificate`}{personalisedSelected && proMembershipSelected && fourWeekPlacementFeature ? <span> &<span className='text-span_color font-bold'> All 3 Add-ons</span></span> : personalisedSelected && proMembershipSelected ? <span>,<span className='text-span_color font-bold'> Personalised Course & 1240+ Courses</span></span> : personalisedSelected && fourWeekPlacementFeature ? <span>,<span className='text-span_color font-bold'> Personalised Course & Placement Support</span></span> : proMembershipSelected && fourWeekPlacementFeature ? <span>,<span className='text-span_color font-bold'> 1240+ Courses & Placement Support</span></span> : personalisedSelected ? <span> & <span className='text-span_color font-bold'> Personalised Course</span></span> : proMembershipSelected ? <span> & <span className='text-span_color font-bold'>1240+ Courses</span>.</span> : fourWeekPlacementFeature ? <span> & <span className='text-span_color font-bold'>Placement Support.</span></span> : selectedAtleastOne ? <span> with <span className='text-span_color font-bold'>Extra 70% Discount</span></span> : selectedSkills?.length > 0 ? <span> with <span className='text-span_color font-bold'>Extra {Math.min(selectedSkills?.length*5, 50)}% Discount</span></span> : ''}</p>
                </div>
                <div className='flex flex-col items-center justify-center'>
                    <p className='md:text-lg'><span className='text-secondary-text font-semibold'>₹{(personaliseAndCertificate ? (100 + price.price) : price.price) + handleDiscountedPrice()}</span></p>
                    <p className='text-xs md:text-sm text-danger'>₹<span className='line-through'>{price?.original_price + handleTotalPrice()}</span></p>
                </div>
            </div>
        </div>
    )
}

export default Stepper