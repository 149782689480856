import { useDispatch, useSelector } from 'react-redux'
import { useAddonContext } from '../../context/AddonFeatureContext'
import { Mixpanel } from '../../config/Mixpanel'
import constants from '../../utilities/constants'
import { selectUserDetails } from '../../redux/otherDetailsSlice'
import LearnersCertification from './LearnersCertification'
import CardBundles from './CardBundles'
import { customCertifiedBundle, selectSelectedItems } from '../../redux/searchSlice'
import CustomisedSkillSelection from './CustomisedSkillSelection'
import { selectTypeformData } from '../../redux/mcqSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

const benefits = [
    {
        image: 'https://ik.imagekit.io/238r9emirjj/certified%20app/Group%20(1).png?updatedAt=1709882026521',
        text: 'Each Certificate boosts your CV’s value by 16%, helping you get hired 2X faster'
    },
    {
        image: 'https://ik.imagekit.io/238r9emirjj/certified%20app/devicon_linkedin%20(1).png?updatedAt=1709882058736',
        text: 'Put on LinkedIn for a 34% chance of employers directly messaging you'
    },
    {
        image: 'https://ik.imagekit.io/238r9emirjj/certified%20app/Group%20(2).png?updatedAt=1709882100043',
        text: 'Gain Access to High-Demand Roles & become a Top Candidate'
    },
]

const NewMultipleCertificateAddon = ({ minutes, seconds, price, handleDiscountedPrice, handleTotalPrice, skillName }) => {

    const userDetails = useSelector(selectUserDetails)
    const selectedSkills = useSelector(selectSelectedItems)
    const typeformData = useSelector(selectTypeformData)
    
    
    const email = userDetails?.email
    
    const { threeCertificates, fiveCertificates, sevenCertificates, handleCertificateSelection, handleForward, showStickyBar, skillIsNotSeo } = useAddonContext()

    const dispatch = useDispatch()

    const createBundle = () => {
        const skillArray = selectedSkills.map((item) => {
            return { course_name: item }
        })
        dispatch(customCertifiedBundle({ course_list: skillArray, bundle_type: 'custom_discounted_certified_bundle', certified_user_skill_id: typeformData?.id }))
        handleForward()
    }

    return (
        <div className='flex flex-col text-[#B0C1CD]'>
            {!skillIsNotSeo ? <CustomisedSkillSelection
                minutes={minutes}
                seconds={seconds}
                createBundle={createBundle}
            /> : <CardBundles
                minutes={minutes}
                seconds={seconds}
            />}
            <div className='mt-5'>
                <h3 className='text-xl md:text-[32px] font-bold mb-2 text-white'>Benefits of Multiple Certificates</h3>
                <div className='bg-[#2D4F71] rounded-2xl px-3 py-2'>
                    {benefits.map((item, index) => (
                        <div key={index} className={`flex ${index !== 2 ? 'border-b-[1.5px]' : ''} border-[#395C7E] items-center gap-2 py-2.5`}>
                            <div className='w-8 h-8'>
                                <img src={item.image} alt="" className='w-full h-auto'/>
                            </div>
                            <p className='flex-1 text-xs-xxs'>{item.text}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className='mt-5'>
                <h3 className='text-xl md:text-[32px] font-bold mb-1.5 text-white'>Certificates Learners Unlocked</h3>
                <LearnersCertification />
            </div>
            <div className='w-full h-10'/>
            {(showStickyBar || selectedSkills?.length > 0 ) && <div className='w-full h-[60px]'/>}
            {(showStickyBar || selectedSkills?.length > 0 ) && <div className='fixed bottom-0 z-10 w-full md:px-10 bg-[#2D4F71] left-1/2 -translate-x-1/2 py-3.5 px-4'>
                {!skillIsNotSeo ? <div className='flex items-center justify-between'>
                    <div className=''>
                        <p className='text-xs'>Certificate saved for:</p>
                        <p className='text-[#E06064] text-xs inline-flex justify-center text-center font-semibold px-1 py-0.5 border-2 border-[#E06064] rounded mt-2'>{minutes.toString().padStart(2, '0')}M : {seconds.toString().padStart(2, '0')}S</p>
                    </div>
                    <div className=''>
                        <div className='flex gap-2 items-center text-sm font-medium py-3 px-5 bg-shadow rounded-lg text-black cursor-pointer' onClick={() => {
                            Mixpanel.track(constants.Mixpanel.STICKY_BAR_CREATE_BUNDLE_BUTTON_CLICKED)
                            createBundle()
                        }}>
                            <p>Unlock {selectedSkills?.length + 1} Certificates</p>
                            <FontAwesomeIcon icon={faArrowRight} className='text-xl arrow-move' />
                        </div>
                        <p className='text-center text-xxs mt-1'>only <span className='text-shadow'>₹{price?.price + handleDiscountedPrice()}</span> <span className='text-danger'>₹</span><span className='text-danger line-through'>{price?.original_price + handleTotalPrice()}</span></p>
                    </div>
                </div> : 
                <div className='flex justify-between'>
                    <div>
                        <p className='text-sm font-semibold'>{threeCertificates ? 3 : fiveCertificates ? 5 : sevenCertificates ? 7 : 0} Certificates Added</p>
                        <p className='text-danger underline text-sm font-semibold cursor-pointer' onClick={() => {
                            Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_BUNDLE_SELECTION_STICKY_BAR_REMOVE_ADD_ON)
                            handleCertificateSelection(threeCertificates ? 1 : fiveCertificates ? 2 : 3)}
                        }>Remove Add-On</p>
                    </div>
                    <div className='bg-[#7FC241] cursor-pointer text-center text-black font-semibold text-lg rounded-lg w-[130px] px-3 py-2' onClick={() => {
                        Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_CERTIFICATES_STICKY_BAR_NEXT_BUTTON, {
                            distinct_id: email
                        })
                        handleForward()
                    }}>
                        Next
                    </div>
                </div>}
            </div>}
        </div>
    )
}

export default NewMultipleCertificateAddon