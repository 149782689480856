import { faChevronLeft, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { selectLoadingStatus, selectModalCourseDetails, selectSuggestedCourseDetail } from '../../redux/postPaymentSlice'
import { filledstars, notFilledstars } from '../NewPostPaymentFeature/CourseDetailsModal'
import CocreatedBy from '../NewPostPaymentFeature/CocreatedBy'
import ProjectsAndOutcomes from '../NewPostPaymentFeature/ProjectsAndOutcomes'
import SyllabusDetails from '../NewPostPaymentFeature/SyllabusDetails'

const CourseSyllabusInsideModal = ({ handleCourseModal, setShowCourseSyllabusDetails }) => {

    const courseDetail = useSelector(selectSuggestedCourseDetail)
    const loading = useSelector(selectLoadingStatus)
    const modalCourseDetails = useSelector(selectModalCourseDetails)

    const outcome = courseDetail?.outcome
    const courseName = modalCourseDetails?.modalCourseName
    const student = modalCourseDetails?.modalCourseNoOfStudent
    const rating = modalCourseDetails?.modalCourseRating

    const lessonRef = useRef(null)

    const lesson = useMemo(() => {
        return courseDetail?.syllabus?.reduce((count, item) => item?.lesson_list?.length + count, 0)
    }, [courseDetail])

    const scrollToLesson = (ref) => {
        if (ref.current){
            ref.current.scrollIntoView({ behavior: 'smooth' })
        }
    }

    return (
        <div className='p-3'>
            <div className='absolute right-3 top-3 cursor-pointer' onClick={() => {
                handleCourseModal()
            }}>
                <FontAwesomeIcon icon={faXmark} className='text-2xl'/>
            </div>
            <div className='pb-3 flex items-start gap-2'>
                {setShowCourseSyllabusDetails && <FontAwesomeIcon icon={faChevronLeft} className='cursor-pointer text-2xl' onClick={() => setShowCourseSyllabusDetails(false)} />}
                <div>
                    <h3 className='text-xl font-medium leading-6 pr-8'>{courseName}</h3>
                    <div className='flex gap-2 items-center mt-1'>
                        <div className='flex'>
                            {filledstars(rating)}
                            {notFilledstars(rating)}
                        </div>
                        <p className='text-xs text-white/50 mt-0.5'>{student} Students</p>
                    </div>
                </div>
            </div>
            <div className='overflow-y-scroll'>
                <div className='bg-bg-2 mt-5 pt-3 pb-2 px-3 rounded-2xl text-xs text-white/50'>
                    {loading ? <div className='flex w-full justify-between gap-3 items-center pb-3 border-b-[1.5px] h-[58px] border-[#335373]'>
                        <div className='flex-1 bg-[#335373] rounded-lg h-full animate-pulse'>

                        </div>
                        <div className='flex-1 bg-[#335373] rounded-lg h-full animate-pulse'>

                        </div>
                        <div className='flex-1 bg-[#335373] rounded-lg h-full animate-pulse'>

                        </div>
                        <div className='flex-1 bg-[#335373] rounded-lg h-full animate-pulse'>

                        </div>
                    </div> : <div className='pb-3 border-b-[1.5px] border-[#335373]'>
                        <div className='flex justify-between text-center px-3'>
                            <div className='flex underline underline-offset-1 flex-col items-center cursor-pointer' onClick={() => scrollToLesson(lessonRef)}>
                                <img src="https://ik.imagekit.io/238r9emirjj/Post%20Payment/Course%20(1).svg?updatedAt=1704512297269" alt="" />
                                <p className='mt-1'>{lesson}</p>
                                <p className='leading-3'>Lessons</p>
                            </div>
                            <div className='flex flex-col items-center'>
                                <img src="https://ik.imagekit.io/238r9emirjj/Post%20Payment/Time.png?updatedAt=1705055727222" width={20} height={20} alt="" />
                                <p className='mt-1'>{`${Math.floor((lesson * 15)/60)}hr ${(lesson*15)%60 === 0 ? '': (lesson*15)%60+'m'}`}</p>
                                <p className='leading-3'>Time</p>
                            </div>
                            <div className='flex flex-col items-center'>
                                <img src="https://ik.imagekit.io/238r9emirjj/Post%20Payment/Certificate%20(1).svg?updatedAt=1704512515093" alt="" />
                                <p className='mt-1'>Certificate Of</p>
                                <p className='leading-3'>Completion</p>
                            </div>
                        </div>
                    </div>}
                    <CocreatedBy/>
                </div>
                <div className='mt-5'>
                    <h3 className='text-lg font-semibold mb-1'>Outcomes</h3>
                    <div className=''>
                        {loading ? <div className=''>
                            {Array.from({ length: 5}, (_, index) => (
                                <div key={index} className={`bg-[#14395F] border-b px-2 py-6 animate-pulse flex items-center gap-2`}>
                                
                                </div>
                            ))}
                        </div>
                        : <ProjectsAndOutcomes
                            list={outcome}
                        />}
                    </div>
                </div>
                <div className='mt-4'>
                    <h3 ref={lessonRef} className='text-lg font-semibold mb-1'>Syllabus</h3>
                    <SyllabusDetails
                        loading={loading}
                    />
                </div>
            </div>
        </div>
    )
}

export default CourseSyllabusInsideModal