import { Outlet, useLocation } from "react-router-dom";
import Logos from "../components/Logos";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux";
import { selectInfluencerDetail } from "../redux/influencersDataSlice";
import { useMemo } from "react";
import { selectPreviousScreen, selectShowBundleCart, selectWhyDoesThisWork, setPreviousScreen, setShowBundleCart, setShowDraftSuggestedBundle, setShowMenu, setShowWhyDoesThisWork } from "../redux/postPaymentSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faBars } from "@fortawesome/free-solid-svg-icons";

const Layout = () => {

  const location = useLocation()
  const { pathname  } = location

  const influencerDetail = useSelector(selectInfluencerDetail)
  const showWhyDoesThisWork = useSelector(selectWhyDoesThisWork)
  const showBundleCart = useSelector(selectShowBundleCart)
  const previousScreen = useSelector(selectPreviousScreen)

  const dispatch = useDispatch()

  const showArrowAndMenu = useMemo(() => {
    return pathname === '/analysis/leaderboard/v2' && (showWhyDoesThisWork || showBundleCart)
  }, [pathname, showBundleCart, showWhyDoesThisWork])
  
  const handleBack = () => {
    const stackLength = previousScreen?.length
    switch (previousScreen[stackLength - 2]){
      case 'why-this-works':
        dispatch(setShowWhyDoesThisWork(true))
        dispatch(setShowBundleCart(false))
        dispatch(setShowDraftSuggestedBundle(false))
        break
      case 'cart-bundle':
        dispatch(setShowWhyDoesThisWork(false))
        dispatch(setShowBundleCart(true))
        dispatch(setShowDraftSuggestedBundle(false))
        break
      case 'drafted-bundle':
        dispatch(setShowWhyDoesThisWork(false))
        dispatch(setShowBundleCart(false))
        dispatch(setShowDraftSuggestedBundle(true))
        break
      default:
        dispatch(setShowWhyDoesThisWork(false))
        dispatch(setShowBundleCart(false))
        dispatch(setShowDraftSuggestedBundle(false))
        break
    }
    dispatch(setPreviousScreen(previousScreen?.slice(0, stackLength - 1)))
  }

  const handleMenu = () => {
    dispatch(setShowMenu(true))
  }

  return (
      <div  className={`w-full h-[100dvh] overflow-y-scroll mx-auto flex flex-col p-4 md:pt-6`} style={{background: 'radial-gradient(53.11% 56.94% at 50% 50%, #244767 0%, #172C3F 100%)'}}>
            <ToastContainer />
            <div className="flex max-w-2xl mx-auto w-full justify-between gap-1 items-center mb-4">
              {showArrowAndMenu && <FontAwesomeIcon icon={faArrowLeft} className="text-white text-2xl cursor-pointer" onClick={handleBack} />}
             {influencerDetail?.colab_logo?.url ? <img src={influencerDetail?.colab_logo?.url} width={198} height={32} alt="" className="w-[198px] h-auto mx-auto mb-4"/> : <Logos margin={'mx-auto'}/>}
              {showArrowAndMenu && <FontAwesomeIcon icon={faBars} className="text-white text-2xl cursor-pointer" onClick={handleMenu} />}
            </div>
            <Outlet />
      </div>
  )
}

export default Layout