import axios from 'axios'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { createContext, useContext, useState } from 'react'
import { API_XANO_BASE_URL } from '../config/api_config'
import { Api_url } from '../common/api_url'
import { Mixpanel } from '../config/Mixpanel'
import constants, { courses, skills } from '../utilities/constants'
import { useDispatch, useSelector } from 'react-redux'
import { selectLandingPageDetails, selectUserDetails } from '../redux/otherDetailsSlice'
import { selectAnswers } from '../redux/answersSlice'
import { selectMCQ, selectMCQDetails, selectTypeformData } from '../redux/mcqSlice'
import { toast } from 'react-toastify'
import { addItemsToPermanentList, addToBundle, fetchSuggestedCourse, removeFromBundle, selectBundlesData, selectCertificateBundleList, selectDashBoardData, selectPersonalizeList, selectSuggestedCourseList } from '../redux/postPaymentSlice'
import { RAZORPAY_KEY } from '../config/api_config'
import { createPaymentOrder } from '../redux/razorPaySlice'
import useRazorpay from 'react-razorpay'
import { selectPriceChart } from '../redux/priceChartSlice'
import { nineNintyNine } from '../pages/AnalysisPage'

const AddonFeatureContext = createContext()

const AddonFeatureProvider = ({ children }) => {

    const landingPage = useSelector(selectLandingPageDetails)
    const userDetails = useSelector(selectUserDetails)
    const answers = useSelector(selectAnswers)
    const questions = useSelector(selectMCQ)
    const suggestedCourseDetails = useSelector(selectSuggestedCourseList)
    const personalizeCourseDetails = useSelector(selectPersonalizeList)
    const certificateBundleDetails = useSelector(selectCertificateBundleList)
    const priceChart = useSelector(selectPriceChart)
    const addOnPurchased = useSelector(selectDashBoardData)
    const bundleDetails = useSelector(selectBundlesData)
    const typeformData = useSelector(selectTypeformData)
    const quizDetails = useSelector(selectMCQDetails)

    const email = userDetails?.email
    const skill_name = landingPage?.skill_name
    const userName = userDetails?.user_name?.split(" ")[0]
    const phoneNo = userDetails?.phone_no
    const userGoal = userDetails?.user_goal
    const rank = userDetails?.user_rank

    const persoanlizeCourseList = personalizeCourseDetails?.custom_personalized_bundle
    const certificateBundleList = certificateBundleDetails?.custom_certified_bundle

    const [ step, setStep ] = useState(1)
    const [ threeCertificates, setThreeCertificates ] = useState(false)
    const [ fiveCertificates, setFiveCertificates ] = useState(false)
    const [ sevenCertificates, setSevenCertificates ] = useState(false)
    const [ personalisedSelected, setPersonalisedSelected ] = useState(false)
    const [ proMembershipSelected, setProMembershipSelected ] = useState(false)
    const [ proMembershipSelectedTypeTwo, setProMembershipSelectedTypeTwo ] = useState(false)
    const [ personaliseAndCertificate, setPersonaliseAndCertificate ] = useState(false)
    const [ fourWeekPlacementFeature, setFourWeekPlacementFeature ] = useState(false)
    const [ oneOnOneFeature, setOneOnOneFeature ] = useState(false)
    const [ showStickyBar, setShowStickyBar ] = useState(false)
    const [ showStickyBar2, setShowStickyBar2 ] = useState(false)
    const [ showFeatures, setShowFeatures ] = useState(false)
    const [ courseName, setCourseName ] = useState('')
    const [ usersData, setUsersData ] = useState([])
    const [ rating, setRating ] = useState(0)
    const [ student, setStudent ] = useState(0)
    const [ showCourseModal, setShowCourseModal ] = useState(false)
    const [ showPlacement, setShowPlacement ] = useState(false)
    const [ optionsSelected, setOptionsSelected ] = useState([])
    const [ showPersonaliseAddon, setShowPersonaliseAddon ] = useState(false)
    const [ addOnSelected, setAddOnSelected ] = useState([])
    const [ showPersonalisedCourseSelectionModal, setShowPersonalisedCourseSelectionModal ] = useState(false)
    const [ showCertificatesSelectionModal, setShowCertificatesSelectionModal ] = useState(false)
    const [ showModal, setShowModal ] = useState(false)
    const [ activeIndex, setActiveIndex ] = useState(0)
    const [ showLoader, setShowLoader ] = useState(false)
    const [ isModalOpen, setIsModalOpen ] = useState(false)
    const [ showCategoryModal, setShowCategoryModal ] = useState(false)
    const [ skillsToShow, setSkillsToShow ] = useState([])
    const [ showSkillList, setShowSkillList ] = useState(false)
    const [ ratings, setRatings ] = useState([])
    const [ students, setStudents ] = useState([])
    const [ courseNameInModal, setCourseNameInModal ] = useState('')
    const [ imageLink, setImageLink ] = useState('')
    const [ showMentorshipModal, setShowMentorshipModal ] = useState(false)
    const [ categoryName, setCategoryName ] = useState('Programming')
    const [ mentorsList, setMentorsList ] = useState([])
    const [ courseSlug, setCourseSlug ] = useState('programming-courses')
    const [ openModal, setOpenModal ] = useState(false)
    const [ showPurchasedListModal, setShowPurchasedListModal ] = useState(false)
    const [ selectedItems, setSelectedItems ] = useState([])
    const [ courseIndex, setCourseIndex ] = useState(0)
    const [ deletedList, setDeletedList ] = useState([])
    const [ selectedSkillList, setSelectedSkillList ] = useState([])
    const [ courseList, setCourseList ] = useState([])
    
    const nothingSelected = !threeCertificates && !fiveCertificates && !sevenCertificates
    const selectedAtleastOne = threeCertificates || fiveCertificates || sevenCertificates
    const secondAddOnSelected = (personalisedSelected && proMembershipSelected) || (proMembershipSelected && fourWeekPlacementFeature)
    const skillIsNotSeo = skill_name?.toLowerCase() !== "seo" && skill_name?.toLowerCase() !== "business analyst" && skill_name?.toLowerCase() !== "sql"
    const skillIsJavaOrGoogleAds = skill_name?.toLowerCase() === "java" || skill_name?.toLowerCase() === "google ads"
    const skillIsBusinessManagement = nineNintyNine.includes(skill_name?.toLowerCase())
    
    const scrollRef = useRef(null)

    const dispatch = useDispatch()
    const Razorpay = useRazorpay()

    const placementFeatureSelected = optionsSelected.some(obj => Object.values(obj).includes('4 Week Placement Support & Interview Training'))
    const suggestedCourseFeatureSelected = optionsSelected.some(obj => Object.values(obj).includes('Personalised Course & Certificate For 5 Subjects'))
    const ownChoicePersonaliseCourse = persoanlizeCourseList?.length > 0
    const mentorShipSelected = optionsSelected.some(obj => Object.values(obj).includes('1:1 Mentorship With Top Industry Experts'))
    const multipleCertificateSelected = certificateBundleList?.length > 0
    const revampYourCvSelected = addOnSelected.some(obj => Object.values(obj).includes('Get Your CV Revamped'))
    const cvSharedWithPartnersSelected = addOnSelected.some(obj => Object.values(obj).includes('Get CV Sent To 967+ Hiring Partners'))
    const practiceInterviewSelected = addOnSelected.some(obj => Object.values(obj).includes('Get A Practice Interview With Experts'))
    const suggestedBundlePurchased = useMemo(() => bundleDetails?.some((item) => (item?.bundle_type === 'suggested_bundle' && item?.is_paid) || (item?.bundle_type === 'refund_suggested_bundle' && item?.is_paid)), [bundleDetails])
    const placementSupportPurchased = useMemo(() => addOnPurchased?.some((item) => item?.product_slug === 'placement_support_2'), [addOnPurchased])
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const pageParam = Math.floor(Math.random() * 11)
                const response = await axios.get(
                    API_XANO_BASE_URL + Api_url.GetOurLearnersWorkAt + `?page=${pageParam}`
                )
                setUsersData(response.data.data)
            } catch (error) {
                console.log(error)
            }
        }
        fetchData()
    }, [])
    
    const handleBackward = (setIsOpen) => {
        if (step === 1){
            Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_HANDLE_BACKWARD_FROM_CERTIFICATES_PAGE, {
                distinct_id: email
            })
            setIsOpen(false)
        } else {
            Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_BACK_BUTTON, {
                distinct_id: email
            })
            setStep(prev => prev - 1)
        }
    }

    const handleForward = () => {
        setStep(prev => prev + 1)
    }

    const newLearners = () => {
        const time = new Date()
        const hours = time.getHours()%12
        const minutes = time.getMinutes()
        let maxLearners = hours.toString() + minutes.toString()
        return Math.max(parseInt(maxLearners)+9, 171)
    }

    const handleCertificateSelection = (number) => {
        if (number === 1){
            if(threeCertificates){
                Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_CERTIFICATES_THREE_CERTIFICATES_REMOVED, {
                    distinct_id: email
                })
                setThreeCertificates(false)
                setSelectedItems(prev => prev.filter(item => {
                    return (skillIsBusinessManagement ? item.product_slug !== 'bundle_type_2:3' : item.product_slug !== 'bundle:3')
                }))
                setShowStickyBar(false)
            }else{
                Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_CERTIFICATES_THREE_CERTIFICATES_SELECTED, {
                    distinct_id: email
                })
                setThreeCertificates(true)
                setSelectedItems(prev => [...prev, {
                    product_slug: skillIsBusinessManagement ? 'bundle_type_2:3' : 'bundle:3',
                    product_quantity: 1,
                }])
                setTimeout(() => setStep(prev => prev + 1), 500)
                setTimeout(() => setShowStickyBar(true), 800)
            }
            if (fiveCertificates){
                setSelectedItems(prev => prev.filter(item => {
                    return (skillIsBusinessManagement ? item.product_slug !== 'bundle_type_2:5' : item.product_slug !== 'bundle:5')
                }))
                setFiveCertificates(false)
            }
            if (sevenCertificates){
                setSelectedItems(prev => prev.filter(item => {
                    return (skillIsBusinessManagement ? item.product_slug !== 'bundle_type_2:7' : item.product_slug !== 'bundle:7')
                }))
                setSevenCertificates(false)
            }
        }else if (number === 2){
            if(fiveCertificates){
                Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_CERTIFICATES_FIVE_CERTIFICATES_REMOVED, {
                    distinct_id: email
                })
                setSelectedItems(prev => prev.filter(item => {
                    return (skillIsBusinessManagement ? item.product_slug !== 'bundle_type_2:5' : item.product_slug !== 'bundle:5')
                }))
                setFiveCertificates(false)
                setShowStickyBar(false)
            }else{
                Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_CERTIFICATES_FIVE_CERTIFICATES_SELECTED, {
                    distinct_id: email
                })
                setFiveCertificates(true)
                setSelectedItems(prev => [...prev, {
                    product_slug: skillIsBusinessManagement ? 'bundle_type_2:5' : 'bundle:5',
                    product_quantity: 1,
                }])
                setTimeout(() => setStep(prev => prev + 1), 500)
                setTimeout(() => setShowStickyBar(true), 800)
            }
            if (threeCertificates){
                setSelectedItems(prev => prev.filter(item => {
                    return (skillIsBusinessManagement ? item.product_slug !== 'bundle_type_2:3' : item.product_slug !== 'bundle:3')
                }))
                setThreeCertificates(false)
            }
            if (sevenCertificates){
                setSelectedItems(prev => prev.filter(item => {
                    return (skillIsBusinessManagement ? item.product_slug !== 'bundle_type_2:7' : item.product_slug !== 'bundle:7')
                }))
                setSevenCertificates(false)
            }
        }else {
            if(sevenCertificates){
                Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_CERTIFICATES_SEVEN_CERTIFICATES_REMOVED, {
                    distinct_id: email
                })
                setSevenCertificates(false)
                setSelectedItems(prev => prev.filter(item => {
                    return (skillIsBusinessManagement ? item.product_slug !== 'bundle_type_2:7' : item.product_slug !== 'bundle:7')
                }))
                setShowStickyBar(false)
            }else{
                Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_CERTIFICATES_SEVEN_CERTIFICATES_SELECTED, {
                    distinct_id: email
                })
                setSevenCertificates(true)
                setSelectedItems(prev => [...prev, {
                    product_slug: skillIsBusinessManagement ? 'bundle_type_2:7' : 'bundle:7',
                    product_quantity: 1,
                }])
                setTimeout(() => setStep(prev => prev + 1), 500)
                setTimeout(() => setShowStickyBar(true), 800)
            }
            if (threeCertificates){
                setThreeCertificates(false)
                setSelectedItems(prev => prev.filter(item => {
                    return (skillIsBusinessManagement ? item.product_slug !== 'bundle_type_2:3' : item.product_slug !== 'bundle:3')
                }))
            }
            if (fiveCertificates){
                setFiveCertificates(false)
                setSelectedItems(prev => prev.filter(item => {
                    return (skillIsBusinessManagement ? item.product_slug !== 'bundle_type_2:5' : item.product_slug !== 'bundle:5')
                }))
            }
        }
    }

    const handlePersonaliseSelection = () => {
        if (personalisedSelected){
            Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_PERSONALISED_COURSE_REMOVED, {
                distinct_id: email
            })
            setSelectedItems(prev => prev.filter(item => item.product_slug !== 'personalized'))
        }else {
            Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_PERSONALISED_COURSE_SELECTED, {
                distinct_id: email
            })
            setSelectedItems(prev => [...prev, {
                product_slug: 'personalized',
                product_quantity: 1,
                entity_type: 'skill',
                entity_id: typeformData?.id
            }])
        }
        setPersonalisedSelected(prev => !prev)
    }

    const handleProMembershipSelection = () => {
        if (proMembershipSelected){
            Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_PRO_MEMBERSHIP_COURSE_REMOVED, {
                distinct_id: email
            })
            setSelectedItems(prev => prev.filter(item => item.product_slug !== 'core_product_purchase'))
        }else{
            Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_PRO_MEMBERSHIP_COURSE_SELECTED, {
                distinct_id: email
            })
            setSelectedItems(prev => [...prev, {
                product_slug: 'core_product_purchase',
                product_quantity: 1,
            }])
        }
        setProMembershipSelected(prev => !prev)
    }

    const handleFourWeekPlacementSelection = () => {
        if (fourWeekPlacementFeature){
            Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_FOUR_WEEK_PLACEMENT_REMOVED, {
                distinct_id: email
            })
            setSelectedItems(prev => prev.filter(item => item.product_slug !== 'placement_support_2'))
        }else{
            Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_FOUR_WEEK_PLACEMENT_SELECTED, {
                distinct_id: email
            })
            setSelectedItems(prev => [...prev, {
                product_slug: 'placement_support_2',
                product_quantity: 1,
            }])
        }
        setFourWeekPlacementFeature(prev => !prev)
    }

    const handleOneOnOnePlacementSelection = () => {
        if (oneOnOneFeature){
            Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_ONE_ON_ONE_MENTORSHIP_REMOVED, {
                distinct_id: email
            })
        }else{
            Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_ONE_ON_ONE_MENTORSHIP_SELECTED, {
                distinct_id: email
            })
        }
        setOneOnOneFeature(prev => !prev)
    }

    const calculateScore = () => {
        let score = 0
        const mcq = []
        for (let i = 0; i < 10; i++){
            let object = {
                quiz_id: questions[i]?.quiz_id,
                user_answer: '',
                is_correct: ''
            }
            if (answers?.[i] !== undefined && answers[i] === questions?.[i]?.answer){
                score += 10
                object.is_correct = 1
            } else {
                object.is_correct = 0
            }
            if (questions?.[i][`option_${answers?.[i]}`]){
                object.user_answer = questions?.[i][`option_${answers?.[i]}`]
            }
            mcq.push(object)
        }
        return { score, mcq }
    }

    const handleSelection = (name, discountedPrice, actualPrice, selectedOptions, setSelectedOptions) => {
        if (selectedOptions?.some(obj => Object.values(obj).includes(name))){
            setSelectedOptions(prev => prev.filter((item) => item.name !== name))
        }else{
            setSelectedOptions(prev => [...prev, {
                name,
                discountedPrice,
                actualPrice
            }])
        }
    }

    const addToCart = useCallback((name, discountedPrice, actualPrice ) => {
        if (optionsSelected?.some(obj => Object.values(obj).includes(name))){
            toast.success("Already Added in Cart")
        }else{
            setOptionsSelected(prev => [...prev, {
                name,
                discountedPrice,
                actualPrice
            }])
            toast.success("Added Successfully to Cart")
        }
    }, [optionsSelected])

    const handleToggle1 = () => {
        if (threeCertificates) {
            Mixpanel.track(constants.Mixpanel.ANALYSIS_PAGE_ADDITIONAL_FEATURE_1_REMOVED, {
                distinct_id: email
            })
            setShowStickyBar(false)
            setSelectedItems(prev => prev.filter(item => {
                return (skillIsBusinessManagement ? item.product_slug !== 'bundle_type_2:3' : item.product_slug !== 'bundle:3')
            }))
        } else {
            Mixpanel.track(constants.Mixpanel.ANALYSIS_PAGE_ADDITIONAL_FEATURE_1,  {
                distinct_id: email
            })
            setSelectedItems(prev => [...prev,  {
                product_slug: skillIsBusinessManagement ? 'bundle_type_2:3' : 'bundle:3',
                product_quantity: 1,
            }])
            setShowStickyBar(true)
            if(fiveCertificates){
                setFiveCertificates(false)
                setSelectedItems(prev => prev.filter(item => {
                    return (skillIsBusinessManagement ? item.product_slug !== 'bundle_type_2:5' : item.product_slug !== 'bundle:5')
                }))
            }
            if(sevenCertificates){
                setSevenCertificates(false)
                setSelectedItems(prev => prev.filter(item => {
                    return (skillIsBusinessManagement ? item.product_slug !== 'bundle_type_2:7' : item.product_slug !== 'bundle:7')
                }))
            }
        }
        setThreeCertificates(prev => !prev)
    }

    const handleToggle2 = () => {
        if (proMembershipSelected) {
            Mixpanel.track(constants.Mixpanel.ANALYSIS_PAGE_ADDITIONAL_FEATURE_2_REMOVED,  {
                distinct_id: email
            })
            setSelectedItems(prev => prev.filter(item => item.product_slug !== 'core_product_purchase'))
        }else{
            Mixpanel.track(constants.Mixpanel.ANALYSIS_PAGE_ADDITIONAL_FEATURE_2,  {
                distinct_id: email
            })
            setSelectedItems(prev => [...prev, {
                product_slug: 'core_product_purchase',
                product_quantity: 1,
            }])
        }
        setProMembershipSelected(!proMembershipSelected)
    }

    const handleToggle3 = () => {
        if (personalisedSelected) {
            Mixpanel.track(constants.Mixpanel.ANALYSIS_PAGE_ADDITIONAL_FEATURE_3_REMOVED,  {
                distinct_id: email
            })
            setSelectedItems(prev => prev.filter(item => item.product_slug !== 'personalized'))
            setPersonalisedSelected(false)
        }else{
            Mixpanel.track(constants.Mixpanel.ANALYSIS_PAGE_ADDITIONAL_FEATURE_3,  {
                distinct_id: email
            })
            setSelectedItems(prev => [...prev, {
                product_slug: 'personalized',
                product_quantity: 1,
                entity_type: 'skill',
                entity_id: typeformData?.id
            }])
            setPersonalisedSelected(true)
        }
        setPersonalisedSelected(!personalisedSelected)
    }

    const handleSkillSelection = (index) => {
        setCourseNameInModal(courses[index]?.name)
        setImageLink(courses[index]?.image)
        setSkillsToShow(skills[index])
        setRatings(Array.from({ length: skills[index].length }, () => Math.random() < 0.5 ? 4.5 : 5))
        setStudents(Array.from({ length: skills[index]?.length }, () => Math.floor(Math.random() * (1500 - 1300 + 1)) + 1300))
        setShowSkillList(true)
    }
    
    const discountedAmount = useCallback(()=> {
        
        const suggestedCourseAmount = ((!showPlacement || suggestedCourseFeatureSelected) && !suggestedBundlePurchased) ? (courseList?.length === 0 ? 0 : courseList?.length === 5 ? priceChart?.[15]?.price : courseList?.length < 5 ? (priceChart?.[15]?.price - (5 - courseList?.length) * 199) : ((courseList?.length - 5) * 199) + priceChart?.[15]?.price) : 0
        const proMembershipAmount = proMembershipSelectedTypeTwo ? priceChart[3]?.price : 0
        const placeMentAmount = ((showPlacement || placementFeatureSelected) && !placementSupportPurchased) ? priceChart?.[34]?.price : 0
        const mentorshipAmount = mentorShipSelected ? priceChart?.[8]?.price : 0
        const otherAmount = addOnSelected.reduce((acc, item) => acc + item.discountedPrice, 0)
        const personaliseBundleAmount = persoanlizeCourseList ? persoanlizeCourseList?.length * priceChart?.[13]?.price : 0
        const certificateBundleAmount = certificateBundleList ? certificateBundleList?.length * priceChart?.[14]?.price : 0
        const amount = suggestedCourseAmount + placeMentAmount + mentorshipAmount + otherAmount + personaliseBundleAmount + certificateBundleAmount + proMembershipAmount
        
        return amount
    },[showPlacement, suggestedCourseFeatureSelected, suggestedBundlePurchased, courseList?.length, priceChart, proMembershipSelectedTypeTwo, placementFeatureSelected, placementSupportPurchased, mentorShipSelected, addOnSelected, persoanlizeCourseList, certificateBundleList])
    
    const actualAmount = useCallback(()=> {
        
        const suggestedCourseAmount = ((!showPlacement || suggestedCourseFeatureSelected) && !suggestedBundlePurchased) ? (courseList?.length === 0 ? 0 : courseList?.length === 5 ? priceChart?.[15]?.original_price : courseList?.length < 5 ? (priceChart?.[15]?.original_price - (5 - courseList?.length) * 499) : ((courseList?.length - 5) * 499) + priceChart?.[15]?.original_price) : 0
        const proMembershipAmount = proMembershipSelectedTypeTwo ? priceChart[3]?.original_price : 0
        const placeMentAmount = ((showPlacement || placementFeatureSelected) && !placementSupportPurchased) ? priceChart?.[9]?.original_price : 0
        const mentorshipAmount = mentorShipSelected ? priceChart?.[8]?.original_price : 0
        const otherAmount = addOnSelected.reduce((acc, item) => acc + item.actualPrice, 0)
        const personaliseBundleAmount = persoanlizeCourseList ? persoanlizeCourseList?.length * priceChart?.[13]?.original_price : 0
        const certificateBundleAmount = certificateBundleList ? certificateBundleList?.length * priceChart?.[14]?.original_price : 0
        const amount = suggestedCourseAmount + placeMentAmount + mentorshipAmount + otherAmount + personaliseBundleAmount + certificateBundleAmount + proMembershipAmount
        return amount
    },[showPlacement, suggestedCourseFeatureSelected, suggestedBundlePurchased, courseList?.length, priceChart, proMembershipSelectedTypeTwo, placementFeatureSelected, placementSupportPurchased, mentorShipSelected, addOnSelected, persoanlizeCourseList, certificateBundleList])

    const razorpayHandler = async (callback, callbackTwo) => {
        try {
            const product_list = []
            const courseNameArray = selectedSkillList.map(item => item?.course_name)
            if(courseNameArray?.length > 0) {
                const response = await dispatch(addToBundle({ course_name_list: courseNameArray, certified_bundle_id: suggestedCourseDetails?.id, type: 'suggested-bundle'})).unwrap()
                const newItems = response?.bundle?.filter((item) => courseNameArray?.some(course => item.course_name === course))
                dispatch(addItemsToPermanentList(newItems))
            }
            const deletingCourseId = deletedList.map(item => item?.id)
            if(deletingCourseId?.length > 0) {
                const response = await dispatch(removeFromBundle({ certified_bundle_id: suggestedCourseDetails?.id, certified_bundle_course_id: deletingCourseId, type: 'suggested-bundle'})).unwrap()
                const newItems = deletedList?.filter((item) => response?.bundle?.some(course => item.course_name !== course?.course_name)).map((item) => ({ course_name: item.course_name }))
                dispatch(addItemsToPermanentList(newItems))
            }
            await dispatch(fetchSuggestedCourse({ certified_user_skill_quiz_id: quizDetails?.id, suggested_bundle_type: 'suggested_bundle' }))
            setSelectedSkillList([])
            setDeletedList([])
            if ((!showPlacement || suggestedCourseFeatureSelected) && !suggestedBundlePurchased){
                product_list.push({
                    product_slug: 'suggested_bundle',
                    product_quantity: 1,
                    entity_type: 'bundle',
                    entity_id: suggestedCourseDetails?.id
                })
            }
            if ((showPlacement || placementFeatureSelected) && !placementSupportPurchased){
                product_list.push({
                    product_slug: 'placement_support_2',
                    product_quantity: 1,
                })
            }
            if (mentorShipSelected){
                product_list.push({
                    product_slug: 'mentorship',
                    product_quantity: 1,
                })
            }
            if (multipleCertificateSelected){
                product_list.push({
                    product_slug: 'custom_certified_bundle',
                    product_quantity: 1,
                    entity_type: 'bundle',
                    entity_id: certificateBundleDetails?.id
                })
            }
            if (ownChoicePersonaliseCourse){
                product_list.push({
                    product_slug: 'custom_personalized_bundle',
                    product_quantity: 1,
                    entity_type: 'bundle',
                    entity_id: personalizeCourseDetails?.id
                })
            }
            if (revampYourCvSelected){
                product_list.push({
                    product_slug: 'cv_revamp',
                    product_quantity: 1,
                })
            }
            if (cvSharedWithPartnersSelected){
                product_list.push({
                    product_slug: 'cv_distribution',
                    product_quantity: 1,
                })
            }
            if (practiceInterviewSelected){
                product_list.push({
                    product_slug: 'practice_interview',
                    product_quantity: 1,
                })
            }
            if (proMembershipSelectedTypeTwo){
                product_list.push({
                    product_slug: 'core_product_purchase',
                    product_quantity: 1,
                })
            }
            
            const response = await dispatch(createPaymentOrder({ items: product_list }))
            const options = {
                key: RAZORPAY_KEY,
                amount: `${ response?.payload?.order_cost * 100}`,
                currency: "INR",
                name: userName,
                image: "",
                order_id: response.payload?.razorpay_order_id,
                modal: {
                    "ondismiss": function () {
                        callback()
                    }
                },
                handler: (res) => {
                    getOrderStatusSuccess(res, callbackTwo)
                    callback();
                },
                prefill: {
                    name: userName,
                    email: email,
                    contact: phoneNo,
                },
                notes: {
                    address: "Razorpay Corporate Office"
                },
                theme: {
                    color: "#26CBA1"
                }
            };
            const rzp1 = new Razorpay(options);
            rzp1.on("payment.failed", function (response) {
                getOrderStatusFailed(response)
                callback()
              });
            rzp1.open();
        } catch (error) {
            console.log(error.message)
            toast.error('Something Went Wrong! Try Again')
        }
       
    }

    const getOrderStatusSuccess = async (response, callbackTwo) => {
        callbackTwo()
        toast.success('Paid Successfully')
    }

    const getOrderStatusFailed = async (response) => {
        toast.error('Something Went Wrong Try Again')
    }

    const handleModal = () => setOpenModal(true)

    const value = {
        handleBackward,
        handleForward,
        handleCertificateSelection,
        step,
        setStep,
        threeCertificates,
        fiveCertificates,
        sevenCertificates,
        nothingSelected,
        selectedAtleastOne,
        personalisedSelected,
        handlePersonaliseSelection,
        proMembershipSelected,
        handleProMembershipSelection,
        setPersonalisedSelected,
        showStickyBar,
        setShowStickyBar,
        setThreeCertificates,
        setProMembershipSelected,
        usersData,
        secondAddOnSelected,
        skillIsNotSeo,
        showFeatures, 
        setShowFeatures,
        setFiveCertificates,
        setSevenCertificates,
        newLearners,
        showStickyBar2,
        setShowStickyBar2,
        scrollRef,
        calculateScore,
        personaliseAndCertificate,
        setPersonaliseAndCertificate,
        handleFourWeekPlacementSelection,
        handleOneOnOnePlacementSelection,
        fourWeekPlacementFeature, 
        setFourWeekPlacementFeature,
        oneOnOneFeature,
        setOneOnOneFeature,
        courseName, 
        setCourseName,
        rating, 
        setRating,
        student,
        setStudent,
        showCourseModal, 
        setShowCourseModal,
        showPlacement, 
        setShowPlacement,
        addToCart,
        optionsSelected, 
        setOptionsSelected,
        showPersonaliseAddon, 
        setShowPersonaliseAddon,
        addOnSelected, 
        setAddOnSelected,
        handleSelection,
        showCertificatesSelectionModal, 
        setShowCertificatesSelectionModal,
        showPersonalisedCourseSelectionModal, 
        setShowPersonalisedCourseSelectionModal,
        showModal, 
        setShowModal,
        activeIndex,
        setActiveIndex,
        skillIsJavaOrGoogleAds,
        handleToggle1,
        handleToggle2,
        handleToggle3,
        showLoader, 
        setShowLoader,
        isModalOpen,
        setIsModalOpen,
        showCategoryModal, 
        setShowCategoryModal,
        skillsToShow, 
        setSkillsToShow,
        showSkillList, 
        setShowSkillList,
        ratings, 
        setRatings,
        courseNameInModal, 
        setCourseNameInModal,
        students, 
        setStudents,
        imageLink, 
        setImageLink,
        handleSkillSelection,
        showMentorshipModal, 
        setShowMentorshipModal,
        categoryName, 
        setCategoryName,
        mentorsList, 
        setMentorsList,
        courseSlug, 
        setCourseSlug,
        discountedAmount,
        ownChoicePersonaliseCourse,
        multipleCertificateSelected,
        practiceInterviewSelected,
        cvSharedWithPartnersSelected,
        revampYourCvSelected,
        actualAmount,
        suggestedCourseFeatureSelected,
        placementFeatureSelected,
        mentorShipSelected,
        razorpayHandler,
        userGoal,
        email,
        openModal, 
        setOpenModal,
        handleModal,
        rank,
        showPurchasedListModal, 
        setShowPurchasedListModal,
        suggestedBundlePurchased,
        selectedItems, 
        setSelectedItems,
        courseIndex, 
        setCourseIndex,
        setProMembershipSelectedTypeTwo,
        proMembershipSelectedTypeTwo,
        skillIsBusinessManagement,
        selectedSkillList, 
        setSelectedSkillList,
        deletedList, 
        setDeletedList,
        courseList, 
        setCourseList
    }

    return (
        <AddonFeatureContext.Provider value={value}>
            {children}
        </AddonFeatureContext.Provider>
    )
}

export default AddonFeatureProvider

export const useAddonContext = () => useContext(AddonFeatureContext)