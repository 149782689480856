import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectUserDetails } from '../../redux/otherDetailsSlice'
import LottieAnimation from '../../components/LottieAnimation'
import thankYou from './thank-you.json'
import { useNavigate } from 'react-router-dom'
import { getDashBoardData, getWhatsAppToken } from '../../redux/postPaymentSlice'
import { selectTypeformData } from '../../redux/mcqSlice'

const NewPaidPostPaymentThankYou = () => {

    const userDetails = useSelector(selectUserDetails)
    const typeformData = useSelector(selectTypeformData)

    const userName = userDetails?.user_name
    const firstName = userName?.split(' ')[0]
    const userGoal = userDetails?.user_goal

    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getDashBoardData({ certified_user_skill_id: typeformData?.id }))
        dispatch(getWhatsAppToken({ certified_user_skill_id: typeformData?.id }))
    }, [dispatch, typeformData?.id])

    return (
        <div className='flex-1 text-white text-center max-w-2xl mx-auto flex flex-col justify-center'>
            <h2 className='text-[28px] font-bold text-span_color'>Congratulations {firstName},</h2>
            <p className='text-white/70'><FontAwesomeIcon icon={faCheck} className='text-check-mark mr-2' />You've unlocked your career path to become a job ready {userGoal}.</p>
            <div className='w-[200px] h-[200px] mx-auto'>
                <LottieAnimation
                    loop={true}
                    animation={thankYou}
                />
            </div>
            <div className='mt-3 text-lg font-semibold bg-secondary-text py-2.5 px-3 rounded-xl text-black cursor-pointer' onClick={() => navigate('/analysis/leaderboard/paid')}>
                Start Learning Now!
            </div>
            <p className='text-sm text-white/70 italic mt-0.5'>Note: A Link To Access Your Career Path Will Be Sent To Your Email Within 2 Hours.</p>
        </div>
    )
}

export default NewPaidPostPaymentThankYou