import { useDispatch, useSelector } from 'react-redux'
import { selectShowCourseModal, setShowCourseModal } from '../../redux/postPaymentSlice'
import { Dialog } from '@headlessui/react'
import { ImageComponent } from './CertificateDownload'
import CourseSyllabusInsideModal from '../PostCertifcatePurchase/CourseSyllabusInsideModal'

export const filledstars = (randomNo) => Array.from({ length: randomNo }, (_, index) => (
    <ImageComponent key={index} source={'https://ik.imagekit.io/238r9emirjj/Post%20Payment/Star.png?updatedAt=1704375792364'} />
))

export const notFilledstars = (randomNo) => Array.from({ length: randomNo === 4.5 ? 1 : 0 }, (_, index) => (
    <ImageComponent key={index} source={'https://ik.imagekit.io/238r9emirjj/Post%20Payment/Star%20(2).png?updatedAt=1704520939066'} />
))


const CourseDetailsModal = () => {

    const showCourseModal = useSelector(selectShowCourseModal)

    const dispatch = useDispatch()

    const handleCourseModal = () => {
        dispatch(setShowCourseModal(!showCourseModal))
    }


    return (
        <div>
            <Dialog
                open={showCourseModal}
                onClose={handleCourseModal}
                as='div'
                className="absolute inset-0 flex flex-col mx-auto text-white"
            >
                <Dialog.Overlay className="fixed inset-0 mx-auto z-50 bg-black opacity-50" />
                <Dialog.Title/>
                <Dialog.Description/>
                <div className={`bg-custom-bg border max-w-2xl rounded-2xl w-[90%] mx-auto my-auto z-[80] relative max-h-[650px] md:max-h-[550px] overflow-y-scroll`}>
                    <CourseSyllabusInsideModal
                        handleCourseModal={handleCourseModal}
                    />
                </div>
            </Dialog>
        </div>
    )
}

export default CourseDetailsModal